import * as React from 'react';
import { graphql } from 'gatsby';
import { useMobile } from '../../../utils/use-series';

import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CSectTitle,
  CLabelCard,
  CCenterSlider,
  CLabelList,
  CFixedImg,
  CustomLink,
  CCard,
} from '../../../components/_index';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const isSp = useMobile();

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        data={{
          title: {
            en: (
              <>
                utage / kasumi <br className="u_sp" />/ aya
              </>
            ),
            ja: '小宴会場 うたげ・かすみ・あや',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/banquet/utage/kv.png',
              },
              imgSp: {
                src: '/assets/images/banquet/utage/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: '宴会・会議',
                path: '/banquet/',
              },
            ],
            current: {
              label: 'うたげ・かすみ・あや',
            },
          }}
        />
      </CJumbotron>
      <div className="l_sect">
        <section className="u_mbExLarge">
          <LWrap>
            <h2 className="c_headingLv2">少人数に適した完全貸切の小宴会場</h2>
            <p className="c_sectLead u_mbMedium">
              ご人数やシーンに合わせて多種多様なパーティスタイルを提案
              <br />
              企業様に限らずご家族でのご利用にもご対応
            </p>
            <CCenterSlider
              data={[
                {
                  img: {
                    src: '/assets/images/banquet/utage/img_utage.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/banquet/utage/img_utage02.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/banquet/utage/img_utage11.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/banquet/utage/img_utage12.png',
                    alt: '',
                  },
                },
              ]}
            />
          </LWrap>
        </section>
        <section className="u_mbLarge">
          <LWrap>
            <h2 className="c_headingLv2">小宴会場 うたげ</h2>
            <CLabelList
              exClass="u_mbMedium"
              data={[
                'パーティー',
                '会議・コンベンション',
                '講演・セミナー',
                'イベント・展示',
              ]}
            />
            <CCard
              col={1}
              exClass="u_mbExLarge"
              data={[
                {
                  img: {
                    src: '/assets/images/banquet/utage/img_utage06.png',
                    alt: '',
                  },
                },
              ]}
            />
            <CSectTitle
              title={{
                ja: '基本情報',
                en: (
                  <>
                    BASIC <br className="u_sp" />
                    INFORMATION
                  </>
                ),
              }}
            />
            <p className="u_mb30">
              広さ 148㎡
              <br />
              収容人数 正餐50名～シアター110名
            </p>
            {!isSp ? (
              <table className="c_fixedTable">
                <thead>
                  <tr>
                    <th style={{ width: '180px' }} rowSpan={2}>
                      宴会場名
                    </th>
                    <th colSpan={7}>収容人数</th>
                  </tr>
                  <tr>
                    <th>ご利用範囲</th>
                    <th>広さ（m²）</th>
                    <th>坪</th>
                    <th>正餐</th>
                    <th>ビッフェ</th>
                    <th>スクール</th>
                    <th>シアター</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>うたげ</th>
                    <td>全室</td>
                    <td>148</td>
                    <td>45</td>
                    <td>50</td>
                    <td>100</td>
                    <td>70</td>
                    <td>110</td>
                  </tr>
                  <tr>
                    <th>うたげ A＋B＋C</th>
                    <td>3/4</td>
                    <td>109</td>
                    <td>33</td>
                    <td>30</td>
                    <td>70</td>
                    <td>50</td>
                    <td>70</td>
                  </tr>
                  <tr>
                    <th>うたげ A＋B、C＋D</th>
                    <td>1/2</td>
                    <td>73</td>
                    <td>22</td>
                    <td>20</td>
                    <td>40</td>
                    <td>40</td>
                    <td>50</td>
                  </tr>
                  <tr>
                    <th>うたげ A、B、C、D</th>
                    <td>1/4</td>
                    <td>37</td>
                    <td>11</td>
                    <td>10</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                </tbody>
              </table>
            ) : (
              <>
                <section className="c_fixedTableScroll u_mb60">
                  <table className="c_fixedTable">
                    <thead>
                      <tr>
                        <th colSpan={4}>正餐</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th style={{ width: '168px' }}>宴会場名/ご利用範囲</th>
                        <th style={{ width: '126px' }}>広さ（㎡）</th>
                        <th style={{ width: '126px' }}>坪</th>
                        <th style={{ width: '126px' }}>収容人数</th>
                      </tr>
                      <tr>
                        <td>
                          エクスクルーシブテーブル
                          <br />/ 全室
                        </td>
                        <td>107</td>
                        <td>32</td>
                        <td>30</td>
                      </tr>
                    </tbody>
                  </table>
                </section>
                <section className="c_fixedTableScroll u_mb60">
                  <table className="c_fixedTable">
                    <thead>
                      <tr>
                        <th colSpan={4}>ビッフェ</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th style={{ width: '168px' }}>宴会場名/ご利用範囲</th>
                        <th style={{ width: '126px' }}>広さ（㎡）</th>
                        <th style={{ width: '126px' }}>坪</th>
                        <th style={{ width: '126px' }}>収容人数</th>
                      </tr>
                      <tr>
                        <td>
                          エクスクルーシブテーブル
                          <br />/ 全室
                        </td>
                        <td>107</td>
                        <td>32</td>
                        <td>50</td>
                      </tr>
                    </tbody>
                  </table>
                </section>
                <section className="c_fixedTableScroll u_mb60">
                  <table className="c_fixedTable">
                    <thead>
                      <tr>
                        <th colSpan={4}>スクール</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th style={{ width: '168px' }}>宴会場名/ご利用範囲</th>
                        <th style={{ width: '126px' }}>広さ（㎡）</th>
                        <th style={{ width: '126px' }}>坪</th>
                        <th style={{ width: '126px' }}>収容人数</th>
                      </tr>
                      <tr>
                        <td>
                          エクスクルーシブテーブル
                          <br />/ 全室
                        </td>
                        <td>107</td>
                        <td>32</td>
                        <td>20</td>
                      </tr>
                    </tbody>
                  </table>
                </section>
                <section className="c_fixedTableScroll u_mb30">
                  <table className="c_fixedTable">
                    <thead>
                      <tr>
                        <th colSpan={4}>シアター</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th style={{ width: '168px' }}>宴会場名/ご利用範囲</th>
                        <th style={{ width: '126px' }}>広さ（㎡）</th>
                        <th style={{ width: '126px' }}>坪</th>
                        <th style={{ width: '126px' }}>収容人数</th>
                      </tr>
                      <tr>
                        <td>
                          エクスクルーシブテーブル
                          <br />/ 全室
                        </td>
                        <td>107</td>
                        <td>32</td>
                        <td>50</td>
                      </tr>
                    </tbody>
                  </table>
                </section>
              </>
            )}
            <p className="u_mbMedium">
              【宴会場付帯設備】
              <br />
              同時通訳設備・美容着付室・写真室・婦人着替室・紳士着替室・授乳室
            </p>
            <CFixedImg
              width={406}
              img={{
                src: '/assets/images/banquet/utage/img_utage03.png',
                alt: '',
              }}
            />
          </LWrap>
        </section>
        <section className="u_mbExLarge">
          <LWrap>
            <CSectTitle
              title={{
                ja: 'レイアウト例',
                en: (
                  <>
                    LAYOUT <br className="u_sp" />
                    EXAMPLE
                  </>
                ),
              }}
            />
            <CCard
              col={2}
              exClass="u_mbExLarge"
              data={[
                {
                  img: {
                    src: '/assets/images/banquet/utage/img_utage04.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/banquet/utage/img_utage05.png',
                    alt: '',
                  },
                },
              ]}
            />
          </LWrap>
        </section>
        <section id="kasumi" className="u_mbLarge">
          <LWrap>
            <h2 className="c_headingLv2">小宴会場 かすみ</h2>
            <CLabelList
              exClass="u_mbMedium"
              data={[
                'パーティー',
                '会議・コンベンション',
                '講演・セミナー',
                'イベント・展示',
              ]}
            />
            <CCard
              col={1}
              exClass="u_mbExLarge"
              data={[
                {
                  img: {
                    src: '/assets/images/banquet/utage/img_utage07.png',
                    alt: '',
                  },
                },
              ]}
            />
            <CSectTitle
              title={{
                ja: '基本情報',
                en: (
                  <>
                    BASIC <br className="u_sp" />
                    INFORMATION
                  </>
                ),
              }}
            />
            <p className="u_mb30">
              広さ 105㎡
              <br />
              収容人数 正餐30名～シアター80名
            </p>
            {!isSp ? (
              <table className="c_fixedTable">
                <thead>
                  <tr>
                    <th style={{ width: '180px' }} rowSpan={2}>
                      宴会場名
                    </th>
                    <th colSpan={7}>収容人数</th>
                  </tr>
                  <tr>
                    <th>ご利用範囲</th>
                    <th>広さ（m²）</th>
                    <th>坪</th>
                    <th>正餐</th>
                    <th>ビッフェ</th>
                    <th>スクール</th>
                    <th>シアター</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>かすみ</th>
                    <td>全室</td>
                    <td>105</td>
                    <td>32</td>
                    <td>30</td>
                    <td>70</td>
                    <td>50</td>
                    <td>80</td>
                  </tr>
                  <tr>
                    <th>かすみ A、B</th>
                    <td>1/2</td>
                    <td>52</td>
                    <td>16</td>
                    <td>10</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                </tbody>
              </table>
            ) : (
              <>
                <section className="c_fixedTableScroll u_mb60">
                  <table className="c_fixedTable">
                    <thead>
                      <tr>
                        <th colSpan={4}>正餐</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th style={{ width: '168px' }}>宴会場名/ご利用範囲</th>
                        <th style={{ width: '126px' }}>広さ（㎡）</th>
                        <th style={{ width: '126px' }}>坪</th>
                        <th style={{ width: '126px' }}>収容人数</th>
                      </tr>
                      <tr>
                        <td>
                          かすみ
                          <br />/ 全室
                        </td>
                        <td>105</td>
                        <td>32</td>
                        <td>30</td>
                      </tr>
                      <tr>
                        <td>
                          うたげ A、B
                          <br />/ 1/2
                        </td>
                        <td>52</td>
                        <td>16</td>
                        <td>10</td>
                      </tr>
                    </tbody>
                  </table>
                </section>
                <section className="c_fixedTableScroll u_mb60">
                  <table className="c_fixedTable">
                    <thead>
                      <tr>
                        <th colSpan={4}>ビッフェ</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th style={{ width: '168px' }}>宴会場名/ご利用範囲</th>
                        <th style={{ width: '126px' }}>広さ（㎡）</th>
                        <th style={{ width: '126px' }}>坪</th>
                        <th style={{ width: '126px' }}>収容人数</th>
                      </tr>
                      <tr>
                        <td>
                          かすみ
                          <br />/ 全室
                        </td>
                        <td>105</td>
                        <td>32</td>
                        <td>70</td>
                      </tr>
                      <tr>
                        <td>
                          うたげ A、B
                          <br />/ 1/2
                        </td>
                        <td>52</td>
                        <td>16</td>
                        <td>-</td>
                      </tr>
                    </tbody>
                  </table>
                </section>
                <section className="c_fixedTableScroll u_mb60">
                  <table className="c_fixedTable">
                    <thead>
                      <tr>
                        <th colSpan={4}>スクール</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th style={{ width: '168px' }}>宴会場名/ご利用範囲</th>
                        <th style={{ width: '126px' }}>広さ（㎡）</th>
                        <th style={{ width: '126px' }}>坪</th>
                        <th style={{ width: '126px' }}>収容人数</th>
                      </tr>
                      <tr>
                        <td>
                          かすみ
                          <br />/ 全室
                        </td>
                        <td>105</td>
                        <td>32</td>
                        <td>50</td>
                      </tr>
                      <tr>
                        <td>
                          うたげ A、B
                          <br />/ 1/2
                        </td>
                        <td>52</td>
                        <td>16</td>
                        <td>-</td>
                      </tr>
                    </tbody>
                  </table>
                </section>
                <section className="c_fixedTableScroll u_mb30">
                  <table className="c_fixedTable">
                    <thead>
                      <tr>
                        <th colSpan={4}>シアター</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th style={{ width: '168px' }}>宴会場名/ご利用範囲</th>
                        <th style={{ width: '126px' }}>広さ（㎡）</th>
                        <th style={{ width: '126px' }}>坪</th>
                        <th style={{ width: '126px' }}>収容人数</th>
                      </tr>
                      <tr>
                        <td>
                          かすみ
                          <br />/ 全室
                        </td>
                        <td>105</td>
                        <td>32</td>
                        <td>80</td>
                      </tr>
                      <tr>
                        <td>
                          うたげ A、B
                          <br />/ 1/2
                        </td>
                        <td>52</td>
                        <td>16</td>
                        <td>-</td>
                      </tr>
                    </tbody>
                  </table>
                </section>
              </>
            )}
            <p className="u_mbMedium">
              【宴会場付帯設備】
              <br />
              同時通訳設備・美容着付室・写真室・婦人着替室・紳士着替室・授乳室
            </p>
            <CFixedImg
              width={557}
              img={{
                src: '/assets/images/banquet/utage/img_kasumi_floor.png',
                alt: '',
              }}
            />
          </LWrap>
        </section>
        <section className="u_mbExLarge">
          <LWrap>
            <CSectTitle
              title={{
                ja: 'レイアウト例',
                en: (
                  <>
                    LAYOUT <br className="u_sp" />
                    EXAMPLE
                  </>
                ),
              }}
            />
            <CCard
              col={2}
              exClass="u_mbExLarge"
              data={[
                {
                  img: {
                    src: '/assets/images/banquet/utage/img_kasumi_layout.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/banquet/utage/img_kasumi_layout02.png',
                    alt: '',
                  },
                },
              ]}
            />
          </LWrap>
        </section>
        <section id="aya" className="u_mbLarge">
          <LWrap>
            <h2 className="c_headingLv2">小宴会場 あや</h2>
            <CLabelList
              exClass="u_mbMedium"
              data={[
                'パーティー',
                '会議・コンベンション',
                '講演・セミナー',
                'イベント・展示',
              ]}
            />
            <CCard
              col={1}
              exClass="u_mbExLarge"
              data={[
                {
                  img: {
                    src: '/assets/images/banquet/utage/img_aya.png',
                    alt: '',
                  },
                },
              ]}
            />
            <CSectTitle
              title={{
                ja: '基本情報',
                en: (
                  <>
                    BASIC <br className="u_sp" />
                    INFORMATION
                  </>
                ),
              }}
            />
            <p className="u_mb30">
              広さ 88㎡
              <br />
              収容人数 正餐20名～シアター60名
            </p>
            {!isSp ? (
              <table className="c_fixedTable">
                <thead>
                  <tr>
                    <th style={{ width: '180px' }} rowSpan={2}>
                      宴会場名
                    </th>
                    <th colSpan={7}>収容人数</th>
                  </tr>
                  <tr>
                    <th>ご利用範囲</th>
                    <th>広さ（m²）</th>
                    <th>坪</th>
                    <th>正餐</th>
                    <th>ビッフェ</th>
                    <th>スクール</th>
                    <th>シアター</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>あや</th>
                    <td>全室</td>
                    <td>88</td>
                    <td>27</td>
                    <td>20</td>
                    <td>50</td>
                    <td>30</td>
                    <td>60</td>
                  </tr>
                  <tr>
                    <th>あや A＋B</th>
                    <td>1/2</td>
                    <td>43</td>
                    <td>13</td>
                    <td>10</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                </tbody>
              </table>
            ) : (
              <>
                <section className="c_fixedTableScroll u_mb60">
                  <table className="c_fixedTable">
                    <thead>
                      <tr>
                        <th colSpan={4}>正餐</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th style={{ width: '168px' }}>宴会場名/ご利用範囲</th>
                        <th style={{ width: '126px' }}>広さ（㎡）</th>
                        <th style={{ width: '126px' }}>坪</th>
                        <th style={{ width: '126px' }}>収容人数</th>
                      </tr>
                      <tr>
                        <td>
                          あや
                          <br />/ 全室
                        </td>
                        <td>88</td>
                        <td>27</td>
                        <td>30</td>
                      </tr>
                      <tr>
                        <td>
                          あや A、B
                          <br />/ 1/2
                        </td>
                        <td>43</td>
                        <td>13</td>
                        <td>10</td>
                      </tr>
                    </tbody>
                  </table>
                </section>
                <section className="c_fixedTableScroll u_mb60">
                  <table className="c_fixedTable">
                    <thead>
                      <tr>
                        <th colSpan={4}>ビッフェ</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th style={{ width: '168px' }}>宴会場名/ご利用範囲</th>
                        <th style={{ width: '126px' }}>広さ（㎡）</th>
                        <th style={{ width: '126px' }}>坪</th>
                        <th style={{ width: '126px' }}>収容人数</th>
                      </tr>
                      <tr>
                        <td>
                          あや
                          <br />/ 全室
                        </td>
                        <td>88</td>
                        <td>27</td>
                        <td>50</td>
                      </tr>
                      <tr>
                        <td>
                          あや A、B
                          <br />/ 1/2
                        </td>
                        <td>43</td>
                        <td>13</td>
                        <td>-</td>
                      </tr>
                    </tbody>
                  </table>
                </section>
                <section className="c_fixedTableScroll u_mb60">
                  <table className="c_fixedTable">
                    <thead>
                      <tr>
                        <th colSpan={4}>スクール</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th style={{ width: '168px' }}>宴会場名/ご利用範囲</th>
                        <th style={{ width: '126px' }}>広さ（㎡）</th>
                        <th style={{ width: '126px' }}>坪</th>
                        <th style={{ width: '126px' }}>収容人数</th>
                      </tr>
                      <tr>
                        <td>
                          あや
                          <br />/ 全室
                        </td>
                        <td>88</td>
                        <td>27</td>
                        <td>30</td>
                      </tr>
                      <tr>
                        <td>
                          あや A、B
                          <br />/ 1/2
                        </td>
                        <td>43</td>
                        <td>13</td>
                        <td>-</td>
                      </tr>
                    </tbody>
                  </table>
                </section>
                <section className="c_fixedTableScroll u_mb30">
                  <table className="c_fixedTable">
                    <thead>
                      <tr>
                        <th colSpan={4}>シアター</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th style={{ width: '168px' }}>宴会場名/ご利用範囲</th>
                        <th style={{ width: '126px' }}>広さ（㎡）</th>
                        <th style={{ width: '126px' }}>坪</th>
                        <th style={{ width: '126px' }}>収容人数</th>
                      </tr>
                      <tr>
                        <td>
                          あや
                          <br />/ 全室
                        </td>
                        <td>88</td>
                        <td>27</td>
                        <td>60</td>
                      </tr>
                      <tr>
                        <td>
                          あや A、B
                          <br />/ 1/2
                        </td>
                        <td>43</td>
                        <td>13</td>
                        <td>-</td>
                      </tr>
                    </tbody>
                  </table>
                </section>
              </>
            )}
            <p className="u_mbMedium">
              【宴会場付帯設備】
              <br />
              同時通訳設備・美容着付室・写真室・婦人着替室・紳士着替室・授乳室
            </p>
            <CFixedImg
              width={406}
              img={{
                src: '/assets/images/banquet/utage/img_aya_floor.png',
                alt: '',
              }}
            />
          </LWrap>
        </section>
        <section className="u_mbExLarge">
          <LWrap>
            <CSectTitle
              title={{
                ja: 'レイアウト例',
                en: (
                  <>
                    LAYOUT <br className="u_sp" />
                    EXAMPLE
                  </>
                ),
              }}
            />
            <CCard
              col={2}
              exClass="u_mbExLarge"
              data={[
                {
                  img: {
                    src: '/assets/images/banquet/utage/img_aya_layout.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/banquet/utage/img_aya_layout02.png',
                    alt: '',
                  },
                },
              ]}
            />
          </LWrap>
        </section>
        <section className="u_mbExLarge">
          <LWrap>
            <CSectTitle
              title={{
                ja: 'メニュー',
                en: <>MENU</>,
              }}
            />
            <CLabelCard
              data={[
                {
                  img: {
                    src: '/assets/images/banquet/img_menu.png',
                    alt: '宴会・パーティー',
                  },
                  title: <>宴会・パーティー</>,
                  link: {
                    href: '/banquet/party/',
                  },
                },
                {
                  img: {
                    src: '/assets/images/banquet/img_menu02.png',
                    alt: '会議・講演・セミナー',
                  },
                  title: <>会議・講演・セミナー</>,
                  link: {
                    href: '/banquet/meeting/',
                  },
                },
                {
                  img: {
                    src: '/assets/images/banquet/img_menu03.png',
                    alt: 'イベント・展示',
                  },
                  title: <>イベント・展示</>,
                  link: {
                    href: '/banquet/event/',
                  },
                },
                {
                  img: {
                    src: '/assets/images/banquet/img_menu04.png',
                    alt: 'インセンティブ',
                  },
                  title: <>インセンティブ</>,
                  link: {
                    href: '/banquet/incentive/',
                  },
                },
                {
                  img: {
                    src: '/assets/images/banquet/img_menu05.png',
                    alt: 'ケータリング',
                  },
                  title: <>ケータリング</>,
                  link: {
                    href: '/banquet/catering/',
                  },
                },
              ]}
            />
          </LWrap>
        </section>
        <section className="u_mbExLarge">
          <LWrap>
            <CSectTitle
              title={{
                ja: '宴会場',
                en: <>BANQUET</>,
              }}
            />
            <CLabelCard
              data={[
                {
                  img: {
                    src: '/assets/images/banquet/img_banquet.png',
                    alt: 'ロイヤルホール',
                  },
                  label: '大宴会場',
                  title: <>ロイヤルホール</>,
                  text: <>ロビーフロア</>,
                  link: {
                    href: '/banquet/grand_hall/',
                  },
                },
                {
                  img: {
                    src: '/assets/images/banquet/img_banquet02.png',
                    alt: 'クラウンルーム',
                  },
                  label: '中宴会場',
                  title: <>クラウンルーム</>,
                  text: <>ガーデンフロア</>,
                  link: {
                    href: '/banquet/crown/',
                  },
                },
                {
                  img: {
                    src: '/assets/images/banquet/img_banquet03.png',
                    alt: 'モンシェリー',
                  },
                  label: '中宴会場',
                  title: <>モンシェリー</>,
                  text: <>ガーデンフロア</>,
                  link: {
                    href: '/banquet/mon_cheri/',
                  },
                },
                {
                  img: {
                    src: '/assets/images/banquet/img_banquet04.png',
                    alt: 'エクスクルーシブテーブル',
                  },
                  label: '小宴会場',
                  title: <>エクスクルーシブテーブル</>,
                  text: <>ガーデンフロア</>,
                  link: {
                    href: '/banquet/exclusive_table/',
                  },
                },
                {
                  img: {
                    src: '/assets/images/banquet/img_banquet05.png',
                    alt: 'うたげ',
                  },
                  label: '小宴会場',
                  title: <>うたげ</>,
                  text: <>ガーデンフロア</>,
                  link: {
                    href: '/banquet/utage/',
                  },
                },
                {
                  img: {
                    src: '/assets/images/banquet/img_banquet06.png',
                    alt: 'かすみ',
                  },
                  label: '小宴会場',
                  title: <>かすみ</>,
                  text: <>ガーデンフロア</>,
                  link: {
                    href: '/banquet/utage#kasumi',
                  },
                },
                {
                  img: {
                    src: '/assets/images/banquet/img_banquet07.png',
                    alt: 'あや',
                  },
                  label: '小宴会場',
                  title: <>あや</>,
                  text: <>ガーデンフロア</>,
                  link: {
                    href: '/banquet/utage#aya',
                  },
                },
              ]}
            />
          </LWrap>
        </section>
      </div>
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
